import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA OPTIONS------
    getProduksiEgg(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/produksi/master/produksi-he", { params: credentials })
    },


    // -----API DATA LIST------
    getHatcheryList() {
        ApiService.setHeader();
        return ApiService.get("v2/nasional/produksi/hatchery")
    },
    getSettingHeList(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/produksi/setting-he", { params: credentials })
    },
    getSettingHeDetailList(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/produksi/setting-he/detail", { params: credentials })
    },


    // -----API HISTORY LIST------
    getRiwayatSettingHE(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/produksi/setting-he/all", { params: credentials })
    },
    getRiwayatPullchick(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/produksi/pullchick", { params: credentials })
    },


    // ------API PROCESS DATA------    
    getSettingHeEdit(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/produksi/setting-he/edit", { params: credentials })
    },
    addSettingHE(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/nasional/produksi/setting-he", credentials)
    },
    updateSettingHE(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/nasional/produksi/setting-he", credentials)
    },
    deleteSettingHE(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/nasional/produksi/setting-he", { data: credentials })
    },

    getPullchickEdit(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/produksi/pullchick/edit", { params: credentials })
    },
    addPullChick(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/nasional/produksi/pullchick", credentials)
    },
    updatePullChick(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/nasional/produksi/pullchick", credentials)
    },
    selesaiPullChick(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/nasional/produksi/pullchick/selesai", credentials)
    },
    deletePullChick(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/nasional/produksi/pullchick", { data: credentials })
    },
}